'use strict'

angular.module('cb.report-service', [])
    .factory('ReportService', ["ApiService", function (ApiService) {

        var reportService = {
            GetSalesmanReports: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetSalesmanReports';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			GetFitterReports: function (data) {
				try {
					var url = '/webapi/api/v1/report/GetFitterReports';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e)
				}
			},
            GetPostcodeErrors: function () {
                try {
                    var url = '/webapi/api/v1/report/GetPostcodeErrors';
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetSalesmanLeadAllocation: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetSalesmanLeadAllocation';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetEnquiryRecords: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetEnquiryRecords';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetUnpaidCommissionReport: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetUnpaidCommission';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetKPIReport: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetKPI';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
			},
			GetCompanyMonthYearKPI: function () {
				try {
					var url = '/webapi/api/v1/report/GetCompanyMonthYearKPI';
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},
            GetMailingROI: function (data) {
                try{
                    var url = '/webapi/api/v1/report/GetMailingROI';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                        console.error('Error!', e);
                }
            },
            GetFitterSurveyOverview: function () {
                try {
                    var url = '/webapi/api/v1/report/GetFitterSurveyOverview';
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetFitterSurveyNotSent: function () {
                try {
                    var url = '/webapi/api/v1/report/GetFitterSurveyNotSent';
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetInstallationAppointmentOverdue: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetInstallationAppointmentOverdue';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetAllWorkInProgress: function (){
                try {
                    var url = '/webapi/api/v1/report/GetAllWorkInProgress';
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetUnresolvedCallsRemakes: function () {
                try {
                    var url = '/webapi/api/v1/report/GetUnresolvedCallsRemakes';
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetLeadProviderCommissionReport: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetLeadProviderCommission';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetOrdersInPeriodReport: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetOrdersInPeriod';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetSoldUnsoldLeadsReport: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetSoldUnsoldLeads';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetEnquirySourceAnalysisReport: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetEnquirySourceAnalysis';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetOrderSummaryReport: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetOrderSummary';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetOrdersInProgressReport: function () {
                try {
                    var url = '/webapi/api/v1/report/GetOrdersInProgress';
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetOrderByActionReport: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetOrderByAction';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetOrderActions: function(){
                try {
                    var url = '/webapi/api/v1/report/GetOrderActions';
                    return ApiService.promiseGet(url);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            GetServiceCallsOutstanding: function (data) {
                try {
                    var url = '/webapi/api/v1/report/GetOutstandingServiceCalls';
                    return ApiService.promisePost(url, data);
                } catch (e) {
                    console.error('Error!', e);
                }
            },
            UpdateJobInProgress: function(data) {
                try {
                    var url = '/webapi/api/v1/report/UpdateJobInProgress';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetMissingMedia: function(data) {
                try {
                    var url = '/webapi/api/v1/report/GetMissingMedia';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetInstallerCurrentWork: function(data) {
                try {
                    var url = '/webapi/api/v1/report/GetInstallerCurrentWork';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetAllStatuses: function() {
                try {
                    var url = '/webapi/api/v1/leadStatus/iPadGetLeadStatus';
                    return ApiService.promiseGet(url);
                }
                catch (e) {
                    console.error('Error!', e);
                }
            },
            GetNewsletterManagement: function(data) {
                try {
                    var url = '/webapi/api/v1/report/GetNewsletterManagement';
                    return ApiService.promisePost(url, data);
                }
                catch (e) {
                    console.error('Error!', e);
                }
			},
			GetAnonBestInCompany: function () {
				try {
					var url = '/webapi/api/v1/report/GetAnonBestInCompany';
					return ApiService.promisePost(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
            },
            GetSurveyUpload: function (data) {
				try {
					var url = '/webapi/api/v1/report/GetSurveyUploadReport';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			GetSurveyVideos: function (data) {
				try {
					var url = '/webapi/api/v1/report/GetSurveyVideosReport';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			GetSalesMonthlyReport: function (data) {
				try {
					var url = '/webapi/api/v1/report/GetSalesMonthlyReport';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			GetTimeSheetReport: function (data) {
				try {
					var url = '/webapi/api/v1/report/GetTimeSheetReport';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			FetchIndividualTimeSheets: function (data) {
				try {
					var url = '/webapi/api/v1/report/FetchIndividualTimeSheets'
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
				GetSalesComparativeMonthlyReport: function (data) {
				try {
					var url = '/webapi/api/v1/report/GetSalesComparativeMonthlyReport';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			GetSalesStatsLeads: function (data) {
				try {
					var url = '/webapi/api/v1/report/GetSalesStatsLeads';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},
			GetSalesStatsOrders: function (data) {
				try {
					var url = '/webapi/api/v1/report/GetSalesStatsOrders';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			}
        };
        return reportService;
    }]);
